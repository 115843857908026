import { IWallet } from '../types/types';
import axiosInstance from './axiosInstance';

// Function to get wallet data, accepting a userID
export const getWallet = async (userID: number) => {
  const { data } = await axiosInstance.get(`/api/wallet/${userID}`); // Pass userID as part of the URL
  return data;
};

// Function to update wallet data, accepting walletData (including userID and other data)
export const updateWallet = async (userID: number, walletData: Partial<IWallet>) => {
  const { data } = await axiosInstance.put(`/api/wallet/${userID}`, walletData); // Pass userID as a URL parameter
  return data;
};


