import React, { useState, useEffect } from 'react';
import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react';
import { useLoadScript } from '@react-google-maps/api';
import clsx from 'clsx';
import { IAddressDetails, IPlaceSuggestion } from '../../types/types';
import { useAddress } from '../../hooks/location';

const libraries: 'places'[] = ['places'];

interface AddressAutocompleteProps {
  label?: string;
  value: string;
  placeholder?: string;
  onChange: (addressData: IAddressDetails, place_id: string) => void;
  addressType: 'location' | 'shippingAddress';
  countries?: string[];
  error?: string;
  className?: string;
  labelClassName?: string;
  disabledInput?: boolean;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  label,
  value,
  placeholder,
  onChange,
  addressType,
  countries = [],
  error,
  className,
  labelClassName,
  disabledInput,
}) => {
  const [query, setQuery] = useState<string>(value || '');
  const [suggestions, setSuggestions] = useState<IPlaceSuggestion[]>([]);
  const [selectedPlaceID, setSelectedPlaceID] = useState<string>('');

  const { data: addressDetails, isLoading: isAddressDetailsLoading } =
    useAddress(selectedPlaceID || '');

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
    libraries,
  });

  useEffect(() => {
    if (!isLoaded || (query && query.length < 3)) return;

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();

    const types = addressType === 'location' ? ['(regions)'] : [];

    autocompleteService.getPlacePredictions(
      {
        input: query,
        types,
        componentRestrictions: countries.length
          ? { country: countries }
          : undefined,
      },
      (predictions) => {
        setSuggestions(predictions || []);
      }
    );
  }, [query, isLoaded]);

  useEffect(() => {
    if (addressDetails && !isAddressDetailsLoading) {
      onChange(addressDetails, selectedPlaceID);
    }
  }, [addressDetails, isAddressDetailsLoading, onChange]);

  const handleSelectSuggestion = (value: string) => {
    setQuery(value);

    const selectedSuggestion = suggestions.find(
      (suggestion) => suggestion.description === value
    );

    if (selectedSuggestion) {
      setSelectedPlaceID(selectedSuggestion.place_id);
    }
  };

  return (
    <div className="relative">
      <label htmlFor="location" className="mb-2 block text-sm font-semibold">
        {label}
      </label>
      <Combobox value={query} onChange={handleSelectSuggestion}>
        <ComboboxInput
          onChange={(e) => setQuery(e.target.value)}
          placeholder={placeholder ? placeholder : 'Search your address'}
          className="w-full rounded-md border border-gray-300 p-2 px-4 text-sm"
          disabled={disabledInput}
        />
        {suggestions.length > 0 && (
          <ComboboxOptions className="absolute top-[60px] z-10 w-full rounded-10 bg-white">
            {suggestions.map((suggestion) => (
              <ComboboxOption
                key={suggestion.place_id}
                value={suggestion.description}
                className="p-2 text-sm hover:bg-gray-200"
              >
                {suggestion.description}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        )}
      </Combobox>
    </div>
  );
};

export default AddressAutocomplete;
