import axiosInstance from './axiosInstance';

export const createBucket = async (bucketData: any) => {
  const { data } = await axiosInstance.post('/api/bucket', bucketData);
  return data;
};

export const deleteBucket = async (bucketName: any) => {
  const { data } = await axiosInstance.delete(`/api/bucket/${bucketName}`);
  return data;
};
