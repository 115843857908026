import React, { useEffect, useState } from 'react';
import Sun from '../assets/sun.png';
import FullScreenLoader from '../components/loader/FullScreenLoader';
import { useGetHomeData } from '../hooks/useHome';
import { Card } from 'antd';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const { data, isLoading } = useGetHomeData();
  const [time, setTime] = useState<Date>(new Date());
  const navigate = useNavigate();
  useEffect(() => {
    const timerID = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timerID);
  }, []);

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const day = date.getDate();
    let suffix = 'th';
    if (day < 11 || day > 20) {
      switch (day % 10) {
        case 1:
          suffix = 'st';
          break;
        case 2:
          suffix = 'nd';
          break;
        case 3:
          suffix = 'rd';
          break;
        default:
          break;
      }
    }
    return date
      .toLocaleDateString('en-US', options)
      .replace(/(\d+)(th)?/, `$1${suffix}`);
  };

  const formatTime = (date: Date): string => {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  };

  if (isLoading) {
    return <FullScreenLoader />;
  }

  const formattedData = data?.userGrowth.map((item: any) => ({
    ...item,
    month: new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
    }).format(new Date(item?.month)),
  }));

  return (
    <div className="dashboard bg-[#F1F2F6]">
      <div className="mb-10 grid grid-cols-3 gap-6 max-md:grid-cols-1">
        {/* Time and Date Card */}
        <div className="rounded-2xl bg-gradient-to-r from-blue-500 to-blue-400 text-white p-6 shadow-lg">
          <div className="flex items-center gap-4">
            <img src={Sun} alt="Sun Icon" className="w-12 h-12" />
            <div>
              <p className="text-xl font-semibold">{formatTime(time)}</p>
              <p className="text-sm">Realtime Insight</p>
            </div>
          </div>
          <div className="mt-4">
            <p className="text-lg font-medium">Today:</p>
            <p className="text-lg">{formatDate(time)}</p>
          </div>
        </div>

        {/* Total Contests Card */}
        <div className="rounded-2xl bg-white p-6 shadow-lg flex flex-col items-center">
          <div className="flex items-center justify-center w-12 h-12 bg-blue-100 rounded-full mb-4">
            <i className="text-blue-600 text-xl fas fa-trophy"></i>
          </div>
          <p className="text-sm text-gray-500">Total Contests</p>
          <h1 className="text-4xl font-light text-blue-600">
            {data?.contestCount || 0}
          </h1>
        </div>

        {/* Total Users Card */}
        <div className="rounded-2xl bg-white p-6 shadow-lg flex flex-col items-center">
          <div className="flex items-center justify-center w-12 h-12 bg-green-100 rounded-full mb-4">
            <i className="text-green-600 text-xl fas fa-users"></i>
          </div>
          <p className="text-sm text-gray-500">Total Users</p>
          <h1 className="text-4xl font-light text-green-600">
            {data?.userCount || 0}
          </h1>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-6 max-lg:grid-cols-1">
        {/* User Growth Graph */}
        <div className="col-span-2 bg-white rounded-2xl p-6">
          <h2 className="text-xl font-semibold mb-4">User Growth</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={formattedData || []}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Latest Contests */}
        <div
          className="bg-white rounded-2xl p-6"
          style={{ maxHeight: '350px', overflowY: 'auto' }}
        >
          <h2 className="text-xl font-semibold mb-4">Latest Contests</h2>
          <div className="flex flex-wrap gap-4">
            {data?.latestContests?.length > 0 ? (
              data.latestContests.map((contest: any) => (
                <div
                  key={contest?.contestID}
                  className="flex items-center bg-gray-100 rounded-lg p-4 shadow-sm cursor-pointer"
                  style={{ width: '100%', maxWidth: '400px' }}
                  onClick={() =>
                    navigate(`/dashboard/contests/edit`, {
                      state: contest?.alias,
                    })
                  }
                >
                  {/* Contest image */}
                  <img
                    src={contest?.preview || '/default-prize-image.png'} // Fallback to a default image
                    alt={contest?.name}
                    className="w-12 h-12 rounded-full object-cover mr-1"
                  />

                  {/* Contest details */}
                  <div className="flex-1">
                    <h3 className="font-medium text-sm">
                      {contest?.name.length > 7
                        ? `${contest.name.slice(0, 7)}...`
                        : contest.name}
                    </h3>
                    <span className="text-sm text-gray-400 line-clamp-2">
                      {contest?.description.length > 10
                        ? `${contest.description.slice(0, 10)}...`
                        : contest.description}
                    </span>
                  </div>

                  {/* Date */}
                  <div className="text-right text-sm text-gray-400">
                    {new Date(contest?.startDate).toLocaleDateString()} <br />
                    {new Date(contest?.startDate).toLocaleTimeString()}
                  </div>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No contests found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
