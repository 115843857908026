import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, ReactNode } from 'react';
import { AiFillDashboard, AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { FaComments, FaUser } from 'react-icons/fa';
import NavItem from './NavItem';
import { MdPrivacyTip } from 'react-icons/md';
import { FaBookReader } from 'react-icons/fa';
import lopo from "../../assets/lopo. (2).png";

const SideBar: React.FC = () => {
  const navigate = useNavigate();
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);
  const [activeNavName, setActiveNavName] = useState<string>('dashboard');
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth); // Track window width

  const toggleMenuHandler = () => {
    setIsMenuActive((prevState) => !prevState);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize); // Add event listener for resize

    // Cleanup the event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures this runs only once on mount and unmount

  // Toggle menu visibility based on window width
  useEffect(() => {
    if (windowWidth < 1024) {
      setIsMenuActive(false); // Hide menu on small screens
    } else {
      setIsMenuActive(true); // Show menu on larger screens
    }
  }, [windowWidth]);

  return (
    <header className="flex h-fit w-full items-center justify-between pt-0 p-4 lg:h-full lg:max-w-[250px] lg:flex-col lg:items-start lg:justify-start lg:p-0">
      {/* logo */}
      <Link to="/">
        <img
          src={
            lopo
          }
          alt="logo"
          className="w-24 h-16 lg:hidden"
        />
      </Link>
      {/* menu burger icon */}
      <div className="cursor-pointer lg:hidden">
        {isMenuActive ? (
          <AiOutlineClose className="h-6 w-6" onClick={toggleMenuHandler} />
        ) : (
          <AiOutlineMenu className="h-6 w-6" onClick={toggleMenuHandler} />
        )}
      </div>
      {/* sidebar container */}
      {isMenuActive && (
        <div className="fixed inset-0 lg:static lg:h-full lg:w-full">
          {/* underlay */}
          <div
            className="fixed inset-0 bg-black opacity-50 lg:hidden"
            onClick={toggleMenuHandler}
          />
          {/* sidebar */}
          <div className="fixed top-0 bottom-0 left-0 z-50 w-3/4 overflow-y-auto bg-white p-4 lg:static lg:h-full lg:w-full lg:p-6">
            <Link to="/">
              <img
                src={
                  lopo
                }
                alt="logo"
                className="w-32 h-24"
              />
            </Link>
            <h4 className="mt-2font-bold text-[#C7C7C7]">MAIN MENU</h4>
            {/* menu items */}
            <div className="mt-6 flex flex-col gap-y-[0.563rem]">
              <NavItem
                title="Dashboard"
                link="/dashboard"
                icon={<AiFillDashboard className="text-xl" />}
                name="dashboard"
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />
              <NavItem
                title="Contests"
                link="/dashboard/contests"
                icon={<FaComments className="text-xl" />}
                name="contests"
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />

              <NavItem
                title="Users"
                link="/dashboard/users"
                icon={<FaUser className="text-xl" />}
                name="users"
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />

              <NavItem
                title="Terms & Conditions"
                link="/dashboard/terms_and_conditions"
                icon={<FaBookReader className="text-xl" />}
                name="terms"
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />

              <NavItem
                title="Privacy Policy"
                link="/dashboard/privacy_policy"
                icon={<MdPrivacyTip className="text-xl" />}
                name="policy"
                activeNavName={activeNavName}
                setActiveNavName={setActiveNavName}
              />
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default SideBar;
