import React, { useState } from 'react';
import {
  Table,
  Spin,
  Skeleton,
  Popconfirm,
  Button,
  Select,
  Modal,
  Dropdown,
  Menu,
  Checkbox,
  Space,
  Avatar,
  Tooltip,
} from 'antd';
import {
  useGetAllParticipants,
  useRemoveParticipant,
  useUpdateParticipant,
} from '../../hooks/useParticipants';
import { DownOutlined } from '@ant-design/icons';

import { showError, showSuccess } from '../../utils/notification';
import { useQueryClient } from '@tanstack/react-query';
import { useUserById } from '../../hooks/useUsers';
import UserDetailModal from './UserDetailModal';
import { useUpdateContest } from '../../hooks/useContests ';
import { EnumContestStatus } from '../../types/types';
import { FaUserCircle } from 'react-icons/fa';
import TableUser from '../../components/TableUser';
import SubmittedContent from '../../components/SubmittedContent';

const ContestParticipants: React.FC<{
  contestID: string;
  disabledForm?: boolean;
  contestWinnerId?: number;
  contestCurrentStatus?: string;
}> = ({ contestID, disabledForm, contestWinnerId, contestCurrentStatus }) => {
  const queryClient = useQueryClient();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingUpdate, setLoadinUpdate] = useState(false);

  const [selectedParticipant, setSelectedParticipant] = useState<any>(null);
  const { data, error, isLoading } = useGetAllParticipants(contestID);
  const { mutate: updateParticipant, isError } = useUpdateParticipant();
  const { mutate: updateContest } = useUpdateContest();

  const { data: userInfo, isLoading: isUserLoading } = useUserById(
    selectedParticipant?.userID
  );

  const { mutate: removeParticipant, error: errorRemove } =
    useRemoveParticipant();

  const handleDelete = (participantId: string) => {
    removeParticipant(participantId, {
      onSuccess: () => {
        showSuccess('Participant removed successfully');
        queryClient.invalidateQueries({ queryKey: ['applicants'] });
      },
      onError: () => {
        showError('Failed to remove participant');
      },
    });
  };

  const handleStatusChange = (participantId: string, status: string) => {
    const updatedData = { status };

    setLoadinUpdate(true);

    updateParticipant(
      { participantId, updatedData },
      {
        onSuccess: () => {
          showSuccess('Participant status updated successfully');
          queryClient.invalidateQueries({ queryKey: ['applicants'] });
          setLoadinUpdate(false);
        },
        onError: () => {
          showError('Failed to update participant status');
          setLoadinUpdate(false);
        },
      }
    );
  };

  const showParticipantDetails = (participant: any) => {
    setSelectedParticipant(participant);
    setIsModalVisible(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedParticipant(null);
  };

  const handleStatusChangeWinner = (id?: number, content?: string) => {
    setLoadinUpdate(true);

    let contestData: any = {
      winnerID: id || null, // If id is provided, set the winner ID, otherwise null
      contentSubmit: content || null, // If content is provided, use it, otherwise default to empty string
    };
    const status = id ? 'winner' : 'ongoing';

    updateContest(
      {
        id: contestID,

        contestData: {
          ...contestData,
          status,
        },
      },
      {
        onSuccess: async () => {
          await queryClient.refetchQueries({
            queryKey: ['all-contests', ''],
            exact: true,
          });

          await queryClient.invalidateQueries({ queryKey: ['contest'] });

          await queryClient.invalidateQueries({ queryKey: ['applicants'] });

          showSuccess('updated', 'winner updated successfully.');

          setLoadinUpdate(false);
        },
        onError: () => {
          showError('Error', 'Failed to update status.');
          setLoadinUpdate(false);
        },
      }
    );
  };

  if (error) {
    showError('Error fetching participants');
    return <div>Error fetching participants</div>;
  }

  const columns = [
    {
      title: 'Participant ID',
      dataIndex: 'participantID',
      key: 'participantID',
    },
    {
      title: 'Participant User',
      dataIndex: 'user',
      key: 'user',
      width: 200,
      render: (user: any) => {
        return <TableUser user={user} />;
      },
    },

    {
      title: 'Content Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string, record: any) => (
        <Select
          disabled={disabledForm ? true : false}
          defaultValue={status}
          onChange={(value) => handleStatusChange(record.participantID, value)}
        >
          <Select.Option value="pending">Pending</Select.Option>
          <Select.Option value="approved">Approved</Select.Option>
          <Select.Option value="rejected">Rejected</Select.Option>
        </Select>
      ),
    },

    {
      title: 'Submitted Content',
      dataIndex: 'submittedContent',
      key: 'submittedContent',
      render: (submittedContent: string) => {
        return <SubmittedContent submittedContent={submittedContent} />;
      },
    },

    {
      title: 'Content Submission Date',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      render: (submissionDate: string) => {
        const date = new Date(submissionDate);
        return date.toLocaleString(); // Format the date
      },
    },

    {
      title: 'Is Winner',
      key: 'isWinner',
      render: (text: any, record: any) => (
        <Checkbox
          checked={record.userID === contestWinnerId}
          disabled={disabledForm}
          onChange={(e) => {
            if (e.target.checked) {
              handleStatusChangeWinner(record.userID, record.submittedContent);
            } else {
              handleStatusChangeWinner(0, '');
            }
          }}
        >
          Winner
        </Checkbox>
      ),
    },

    {
      title: 'Actions',
      key: 'actions',
      render: (text: any, record: any) => (
        <div>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="view">
                  <Button
                    type="link"
                    onClick={() => showParticipantDetails(record)}
                  >
                    View Details
                  </Button>
                </Menu.Item>
                <Menu.Item key="delete">
                  <Popconfirm
                    title="Are you sure to delete this participant?"
                    onConfirm={() => handleDelete(record.participantID)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button
                      disabled={disabledForm ? true : false}
                      type="link"
                      danger
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Space>
              Actions <DownOutlined />
            </Space>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <div className="p-4 ">
      <h2 className="text-2xl font-semibold mb-4">
        Contest Participants {data?.length}
      </h2>

      <Table
        columns={columns}
        dataSource={data || []}
        rowKey="participantID"
        pagination={{ pageSize: 10 }}
        scroll={{ x: 'max-content' }}
        loading={isLoading || loadingUpdate}
      />

      <UserDetailModal
        isModalVisible={isModalVisible}
        closeModal={closeModal}
        userInfo={userInfo}
        isUserLoading={isUserLoading}
        selectedParticipant={selectedParticipant}
      />
    </div>
  );
};

export default ContestParticipants;
