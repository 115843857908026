import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { FaImage } from 'react-icons/fa';

const SubmittedContent: React.FC<{ submittedContent: string }> = ({
  submittedContent,
}) => {
  const [mediaPreview, setMediaPreview] = useState<string | null>(null);
  const [jsonDetails, setJsonDetails] = useState<any>(null);

  // Function to check if the URL is an image or video
  const isImageUrl = (url: string) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/i);
  };

  const isVideoUrl = (url: string) => {
    return url.match(/\.(mp4|mov|avi|mkv)$/i);
  };

  const handleMediaPreview = (submittedContent: string) => {
    try {
      const parsedContent = JSON.parse(submittedContent);
      setJsonDetails(parsedContent);
      if (parsedContent?.url) {
        setMediaPreview(parsedContent.url);
      }
    } catch (e) {
      console.error('Invalid content format', e);
    }
  };

  // Remove URL from jsonDetails before displaying
  const filteredJsonDetails = jsonDetails
    ? {
        ...jsonDetails,
        url: undefined, // Exclude URL from the display
      }
    : null;

  return (
    <div>
      {submittedContent && (
        <div>
          <Button
            type="link"
            icon={<FaImage />}
            onClick={() => handleMediaPreview(submittedContent)}
          >
            View Media
          </Button>
        </div>
      )}

      {/* Modal for media preview */}
      <Modal
        visible={mediaPreview !== null}
        footer={null}
        onCancel={() => setMediaPreview(null)}
        title="Media Preview"
      >
        {mediaPreview && (
          <div>
            {/* Check if the URL is an image */}
            {isImageUrl(mediaPreview) && (
              <img
                alt="Media Preview"
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '500px',
                  maxHeight: '500px',
                }}
                src={mediaPreview}
              />
            )}

            {/* Check if the URL is a video */}
            {isVideoUrl(mediaPreview) && (
              <video
                controls
                style={{
                  width: '100%',
                  height: 'auto',
                  maxWidth: '500px',
                  maxHeight: '500px',
                }}
                src={mediaPreview}
              >
                Your browser does not support the video tag.
              </video>
            )}

            <div>
              {/* Display the filtered JSON details */}
              {filteredJsonDetails && (
                <pre>{JSON.stringify(filteredJsonDetails, null, 2)}</pre>
              )}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default SubmittedContent;
