import React from 'react';
import { Modal, Spin } from 'antd';

interface UserDetailModalProps {
  isModalVisible: boolean;
  closeModal: () => void;
  userInfo: any;
  isUserLoading: boolean;
  selectedParticipant: any;
}

const UserDetailModal: React.FC<UserDetailModalProps> = ({
  isModalVisible,
  closeModal,
  userInfo,
  isUserLoading,
  selectedParticipant,
}) => {
  // Function to check if the URL is an image
  const isImageUrl = (url: string) => {
    return url.match(/\.(jpeg|jpg|gif|png)$/i);
  };

  // Function to check if the URL is a video
  const isVideoUrl = (url: string) => {
    return url.match(/\.(mp4|mov|avi|mkv)$/i);
  };

  return (
    <Modal
      title="Participant Details"
      visible={isModalVisible}
      onCancel={closeModal}
      footer={null}
      centered
      width={600}
    >
      <div style={{ padding: '20px' }}>
        <h2 style={{ fontSize: '20px', marginBottom: '20px' }}>
          User Information
        </h2>
        {isUserLoading ? (
          <Spin size="large" />
        ) : (
          userInfo && (
            <>
              {/* Centered User Image */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                <img
                  src={userInfo.photo}
                  alt="User"
                  style={{
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                  }}
                />
              </div>

              <div style={{ marginBottom: '10px' }}>
                <strong>User Name:</strong> {userInfo.userName}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>Email:</strong> {userInfo.email}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>Location:</strong> {userInfo.location}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>Gender:</strong> {userInfo.gender}
              </div>
              <div style={{ marginBottom: '10px' }}>
                <strong>Age:</strong> {userInfo.age}
              </div>
              <div style={{ marginBottom: '20px' }}>
                <strong>Sign Up Date:</strong>{' '}
                {new Date(userInfo.signUpDate).toLocaleString()}
              </div>
            </>
          )
        )}

        {selectedParticipant ? (
          <>
            <h2 style={{ fontSize: '20px', marginBottom: '20px' }}>
              Participant Information
            </h2>

            <div style={{ marginBottom: '10px' }}>
              <strong>Participant ID:</strong>{' '}
              {selectedParticipant.participantID}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <strong>User ID:</strong> {selectedParticipant.userID}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <strong>Status:</strong> {selectedParticipant.status}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <strong>Submission Date:</strong>{' '}
              {new Date(selectedParticipant.submissionDate).toLocaleString()}
            </div>
            <div style={{ marginBottom: '10px' }}>
              <strong>Created At:</strong>{' '}
              {new Date(selectedParticipant.createdAt).toLocaleString()}
            </div>
            <div style={{ marginBottom: '20px' }}>
              <strong>Updated At:</strong>{' '}
              {new Date(selectedParticipant.updatedAt).toLocaleString()}
            </div>

            {selectedParticipant.submittedContent && (
              <>
                <div style={{ marginBottom: '10px' }}>
                  <strong>File Name:</strong>{' '}
                  {JSON.parse(selectedParticipant.submittedContent).fileName}
                </div>
                <div style={{ marginBottom: '10px' }}>
                  <strong>File Size:</strong>{' '}
                  {JSON.parse(selectedParticipant.submittedContent).fileSize}{' '}
                  bytes
                </div>
                {/* Conditionally Render Image or Video based on the URL */}
                {isImageUrl(
                  JSON.parse(selectedParticipant.submittedContent).url
                ) ? (
                  <img
                    src={JSON.parse(selectedParticipant.submittedContent).url}
                    alt="Submitted Content"
                    style={{
                      width: '100%',
                      marginTop: '10px',
                      maxWidth: '500px',
                      maxHeight: '500px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                ) : isVideoUrl(
                    JSON.parse(selectedParticipant.submittedContent).url
                  ) ? (
                  <video
                    controls
                    style={{
                      width: '100%',
                      maxWidth: '500px',
                      maxHeight: '500px',
                      marginTop: '10px',
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}
                    src={JSON.parse(selectedParticipant.submittedContent).url}
                  >
                    Your browser does not support the video tag.
                  </video>
                ) : (
                  <p>Unsupported media type</p>
                )}
              </>
            )}
          </>
        ) : (
          <p>No participant selected</p>
        )}
      </div>
    </Modal>
  );
};

export default UserDetailModal;
