import React, { useEffect, useState } from 'react';
import {
  Table,
  Input,
  Button,
  Dropdown,
  Menu,
  Space,
  Select,
  Skeleton,
  Modal,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DownOutlined } from '@ant-design/icons';
import {
  useDeleteContest,
  useGetContests,
  useUpdateContest,
} from '../../hooks/useContests ';
import { showSuccess, showError } from '../../utils/notification';
import { IContest } from '../../types/types';
import Loader from '../../components/loader/Loader';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import FullScreenLoader from '../../components/loader/FullScreenLoader';

enum EnumContestStatus {
  draft = 'draft',
  ongoing = 'ongoing',
  upcoming = 'upcoming',
  winner = 'winner',
  finished = 'finished',
}

const ManageContests: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchColumn, setSearchColumn] = useState<string>('');

  const [loading, setLoading] = useState<boolean>(false);

  const [filters, setFilters] = useState<{ [key: string]: any }>({});
  const [selectedStatus, setSelectedStatus] = useState<string | undefined>(
    filters.status
  );

  const navigate = useNavigate();
  const debouncedSearch = useDebouncedValue(searchTerm, 1000);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: contests,
    isLoading,
    isError,
  } = useGetContests(debouncedSearch, filters, currentPage, pageSize);
  const { mutate: updateContest } = useUpdateContest();
  const { mutate: deleteContest } = useDeleteContest();

  const queryClient = useQueryClient();

  const handleFilterChange = (field: string, value: any) => {
    setFilters({ ...filters, [field]: value });
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const removeFilter = (key: string) => {
    setFilters((prevFilters) => {
      const { [key]: _, ...rest } = prevFilters;
      return rest;
    });
  };

  // Dropdown menu actions
  const menu = (contestID: number, alias: any) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() =>
          navigate(`/dashboard/contests/edit`, {
            state: {
              alias,
            },
          })
        }
      >
        Edit
      </Menu.Item>

      <Menu.Item
        key="3"
        danger
        onClick={() => {
          Modal.confirm({
            title: 'Are you sure you want to to delete contest?',
            content: 'All media related to this contest will also be deleted',
            okText: 'Yes, Delete',
            cancelText: 'Cancel',
            onOk: () => {
              handleDelete(contestID);
            },
          });
        }}
      >
        Delete
      </Menu.Item>
      <Menu.Item
        key="4"
        onClick={() =>
          navigate(`/dashboard/contests/details`, {
            state: {
              alias,
              disabledForm: true,
            },
          })
        }
      >
        Details
      </Menu.Item>
    </Menu>
  );

  const handleDelete = (contestID: number) => {
    setLoading(true)
    deleteContest(contestID.toString(), {
      onSuccess: () => {
        showSuccess('Deleted', `Contest ${contestID} deleted successfully.`);
        queryClient.invalidateQueries({ queryKey: ['all-contests'] });
        setLoading(false);
      },
      onError: (error) => {
        showError('Error', `Failed to delete contest ${contestID}.`);
        console.error(error);
        setLoading(false);
      },
    });
  };

  const handleStatusChange = (id: number, status: EnumContestStatus) => {
    setLoading(true);
    updateContest(
      { id: id.toString(), contestData: { status } },
      {
        onSuccess: () => {
          showSuccess('updated', 'Status updated successfully.');
          queryClient.invalidateQueries({ queryKey: ['all-contests'] });
          setLoading(false);
        },
        onError: () => {
          showError('Error', 'Failed to update status.');
          setLoading(false);
        },
      }
    );
  };

  const columns: ColumnsType<IContest> = [
    {
      title: 'Contest ID',
      dataIndex: 'contestID',
      key: 'contestID',
      sorter: (a, b) => a.contestID - b.contestID,
    },
    {
      title: 'Contest Name',
      dataIndex: 'name',
      key: 'name',
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className="p-2" style={{ width: 250 }}>
          <Input
            placeholder="Filter Name"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value]); // Update selected keys
              setSearchColumn(e.target.value); // Update your local state
            }}
            style={{ display: 'block' }}
          />
          {filters.name && (
            <span style={{ fontSize: '10px' }}>
              <strong>Last searched text</strong> : {filters.name}
            </span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleFilterChange('name', searchColumn); // Apply the filter
                confirm(); // Close the dropdown and apply filter
              }}
              style={{ width: '48%' }}
            >
              Search
            </Button>
            <Button
              size="small"
              onClick={() => {
                setSelectedKeys([]); // Clear the selected keys
                setSearchColumn(''); // Clear local search column state
                handleFilterChange('name', ''); // Reset your filter value
                confirm(); // Confirm reset and close dropdown
              }}
              style={{ width: '48%' }}
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    },

    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className="p-2" style={{ width: 250 }}>
          <Input
            placeholder="Filter Country"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value]); // Update selected keys
              setSearchColumn(e.target.value); // Update your local state
            }}
            style={{ display: 'block' }}
          />
          {filters.country && (
            <span style={{ fontSize: '10px' }}>
              <strong>Last searched text</strong> : {filters.country}
            </span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleFilterChange('country', searchColumn); // Apply the filter
                confirm(); // Close the dropdown and apply filter
              }}
              style={{ width: '48%' }}
            >
              Search
            </Button>
            <Button
              size="small"
              onClick={() => {
                setSelectedKeys([]); // Clear the selected keys
                setSearchColumn(''); // Clear local search column state
                handleFilterChange('country', ''); // Reset your filter value
                confirm(); // Confirm reset and close dropdown
              }}
              style={{ width: '48%' }}
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      filterDropdown: ({ confirm, setSelectedKeys, selectedKeys }) => (
        <div className="p-2" style={{ width: 250 }}>
          <Input
            placeholder="Filter City"
            value={selectedKeys[0]}
            onChange={(e) => {
              setSelectedKeys([e.target.value]); // Update selected keys
              setSearchColumn(e.target.value); // Update your local state
            }}
            style={{ display: 'block' }}
          />
          {filters.city && (
            <span style={{ fontSize: '10px' }}>
              <strong>Last searched text</strong> : {filters.city}
            </span>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 8,
            }}
          >
            <Button
              type="primary"
              size="small"
              onClick={() => {
                handleFilterChange('city', searchColumn); // Apply the filter
                confirm(); // Close the dropdown and apply filter
              }}
              style={{ width: '48%' }}
            >
              Search
            </Button>
            <Button
              size="small"
              onClick={() => {
                setSelectedKeys([]);
                setSearchColumn('');
                handleFilterChange('city', '');
                confirm();
              }}
              style={{ width: '48%' }}
            >
              Reset
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: EnumContestStatus, record) => (
        <Select
          value={status}
          onChange={(newStatus) =>
            handleStatusChange(record.contestID, newStatus as EnumContestStatus)
          }
          className="w-full"
          disabled={status === EnumContestStatus.winner} // Disable the entire select if status is "winner"
        >
          {Object.values(EnumContestStatus).map((statusOption) => (
            <Select.Option
              key={statusOption}
              value={statusOption}
              disabled={
                statusOption === EnumContestStatus.winner &&
                status !== EnumContestStatus.winner
              } // Disable "winner" option if the current status is not "winner"
            >
              {statusOption}
            </Select.Option>
          ))}
        </Select>
      ),

      filterDropdown: ({
        confirm,
        setSelectedKeys,
        selectedKeys,
        clearFilters,
      }) => (
        <div className="p-2">
          <Select
            placeholder="Select Status"
            value={selectedStatus || selectedKeys[0]} // Sync selected value
            onChange={(value: any) => {
              setSelectedKeys(value ? [value] : []); // Update selected key
              setSelectedStatus(value); // Sync with selectedStatus state

              if (value) {
                handleFilterChange('status', value); // Apply selected value to filters
              } else {
                removeFilter('status'); // Remove status filter if no value is selected
              }
              confirm(); // Confirm the filter
            }}
            style={{ width: '100%', marginBottom: '8px' }}
            allowClear
          >
            {Object.values(EnumContestStatus)?.map((status) => (
              <Select.Option key={status} value={status}>
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </Select.Option>
            ))}
          </Select>
        </div>
      ),
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Credits',
      dataIndex: 'creditsRequired',
      key: 'creditsRequired',
      sorter: (a, b) => a.creditsRequired - b.creditsRequired,
    },
    {
      title: 'People Joined',
      dataIndex: 'participantsCount',
      key: 'participantsCount',
      sorter: (a, b) => a.participantsCount - b.participantsCount,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Dropdown
          overlay={menu(record.contestID, record.alias)}
          trigger={['click']}
        >
          <Space>
            Actions <DownOutlined />
          </Space>
        </Dropdown>
      ),
    },
  ];

  if (isError) {
    showError('', 'Error loading contests.');
    return <div>Error loading contests.</div>;
  }

  return (
    <div className="p-4 lg:max-w-[calc(100vw-320px)]">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Manage Contests</h1>
        <Button
          type="primary"
          onClick={() => navigate('/dashboard/contests/addnew')}
        >
          Create New Contest
        </Button>
      </div>
      <Input
        placeholder="Search contests for name, description, location..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4"
      />

      <Table
        columns={columns}
        dataSource={contests?.contestList || []}
        rowKey="contestID"
        loading={isLoading || loading}
        pagination={{
          current: currentPage,
          pageSize,
          total: contests?.totalCount,
          onChange: (page, pageSize) =>
            handleTableChange({ current: page, pageSize }),
          showSizeChanger: true,
        }}
        scroll={{ x: 'max-content' }}
      />
    </div>
  );
};

export default ManageContests;
