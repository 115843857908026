import { useQuery } from '@tanstack/react-query';
import { getAddress } from '../services/location';

export const useAddress = (place_id: string) => {
  return useQuery({
    queryKey: ['address', place_id],
    queryFn: getAddress,
    enabled: !!place_id, // Enables the query only if place_id is defined
  });
};
